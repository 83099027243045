<template>
<v-container>
    <v-card class="component1">
        <v-card-title>
            My Assets
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            >
            <template v-slot:item.change="{item}">
                <span v-if="item.change > 0" class="primary--text">{{item.change}}%</span>
                <span v-if="item.change < 0" class="error--text">{{item.change}}%</span>
            </template>
        </v-data-table>
    </v-card>
</v-container>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    'symbol': 'wBTC',
                    'name': 'Wrapped Bitcoin',
                    'owned': '0.3',
                    'price': '$21,600',
                    'value': '$4000',
                    'change': '2.3'

                }
            ],
            headers: [
                {'text':'Symbol',
                'value': 'symbol'},
                {'text':'Name',
                'value': 'name'},
                {'text':'Owned',
                'value': 'owned'},
                {'text':'Current Price',
                'value': 'price'},
                {'text':'Total Value',
                'value': 'value'},
                {'text':'Change (24h)',
                'value': 'change'}
            ]
        }
    }
}
</script>