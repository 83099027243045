<template>
    <v-container>
        <v-row>
            <v-col cols=12 md=6 lg=3>
                <InfoPod :data="infopods.assets" />
            </v-col>
            <v-col cols=12 md=6 lg=3>
                <InfoPod :data="infopods.investments" />
            </v-col>
            <v-col cols=12 md=6 lg=3>
                <InfoPod :data="infopods.invested" />
            </v-col>
            <v-col cols=12 md=6 lg=3>
                <InfoPod :data="infopods.earned" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=12 md=6 lg=4 xl=3>
                <v-row>
                    <v-col cols=12 lg=6>
                        <CategoryCard :categoryData="categoryCards.lending" />
                    </v-col>
                                        <v-col cols=12 lg=6>
                        <CategoryCard :categoryData="categoryCards.assets" />
                    </v-col>
                                        <v-col cols=12 lg=6>
                                            
                        <CategoryCard :categoryData="categoryCards.derivatives" />

                    </v-col>
                    
                                        <v-col cols=12 lg=6>
                        <CategoryCard :categoryData="categoryCards.dexes" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col lg=8 xl=6>
                <AssetTable />
                <LendingTable />

            </v-col>
        </v-row>

    </v-container>
</template>

<script>

import InfoPod from '../../components/Base/InfoPod'
import CategoryCard from '../../components/Base/CategoryCard'
import AssetTable from '../../components/Base/AssetTable'
import LendingTable from '../../components/Base/LendingTable'

export default {

    components: {
        InfoPod,
        CategoryCard,
        AssetTable,
        LendingTable
    },
    data() {
        return {
                    chartOptions: { 
            chart: {
                toolbar: {
                    show: false
                },
                height: 'auto'
            },
            colors: ['#F7931A', '#627EEA', '#C42727','#11F4AB'],
            theme: {
                mode: 'dark'
            },

            dataLabels: {
                enabled: false
            },
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.6,
            inverseColors: true,
            type: 'vertical',
            opacityFrom: 1,
            opacityTo: 1,
          }},
        stroke: {
          width: 4,
          curve: 'smooth'
        },
        },
            infopods: {
                assets: {
                'icon': 'mdi-briefcase',
                'title': "Total Assets",
                'value': '1600',
                'units': 'USD',
                'color': 'profit--text',
                'gradient': '-webkit-linear-gradient(135deg,#F7931A 20%, black 150%)',
                'chart_data': [1200, 1500, 1800, 2000]
                },
                investments: {
                'icon': 'mdi-rhombus-split',
                'title': "Total Investments",
                'value': '3',
                'units': '',
                'color': 'profit--text',
                'gradient': '-webkit-linear-gradient(135deg,#627EEA 20%, black 150%)',
                'gradientReverse': '-webkit-linear-gradient(10deg,#627EEA 20%, black 150%)',
                'chart_data': [1, 3, 5, 6]
                },
                invested: {
                'icon': 'mdi-account-cash',
                'title': "Total Invested",
                'value': '1356',
                'units': 'USD',
                'color': 'profit--text',
                'gradient': '-webkit-linear-gradient(135deg,#C42727 20%, black 150%)',
                'chart_data': [1000, 1100, 1100, 1100]

                },
                earned: {
                'icon': 'mdi-cash',
                'title': "Total Earned",
                'value': '214',
                'units': 'USD',
                'color': 'profit--text',
                'gradient': '-webkit-linear-gradient(135deg,#11F4AB 20%, black 150%)',
                'chart_data': [100, 140, 200, 280]
                }               
            },
            categoryCards: {
                lending: {
                    'title': 'Lending',
                    'value': [0, 2, 3, 3, 4, 10, 14, 21, 22, 21, 23, 35, 42, 55, 75],
                    'overlay': false
                },
                assets: {
                    'title': 'Assets',
                    'value': [5, 4, 3, 2, 9, 10, 11, 7, 22, 21, 22, 22, 25, 22, 34],
                    'overlay': false
                },
                dexes: {
                    'title': 'Liquidity',
                    'value': [4, 2, 3, 2, 4, 10, 14, 21, 16, 16, 23, 35, 77, 66, 122]
                },
                derivatives: {
                    'title': 'Staking',
                    'value': [0, 2, 3, 3, 4, 10, 14, 21, 22, 21, 23, 35, 42, 55, 75]
                }
            }
        }
    },
    computed: {
        series: function() {
        
            let series = [
          {
            name: 'Total Assets',
            data: this.infopods.assets.chart_data
          },
        {
            name: 'Total Investments',
            data: this.infopods.investments.chart_data
          },
                    {
            name: 'Total Invested',
            data: this.infopods.invested.chart_data
          },
                    {
            name: 'Total Earned',
            data: this.infopods.earned.chart_data
          }
        ]
        
        return series

        } 
    }
    
}
</script>