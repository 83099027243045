<template>
            <v-card :style="{'background': data.gradient}"  align="center">
                <v-card-title>
                    <v-row align="center" no-gutters>
                        <v-col cols=12 lg=4>
                            <v-icon x-large >{{data.icon}}</v-icon>
                        </v-col>
                        <v-col cols=12 lg=8>
                            <v-row no-gutters>
                                <v-col cols=12 lg=12>
                                    <span class="subtitle-2">
                                        {{data.title}}
                                    </span>
                                </v-col>
                                <v-col cols=12 lg=12>
                                    <span class="headline">
                                        {{data.value}} {{data.units}}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-title>
               
            </v-card>
</template>

<script>

export default {
    props: ['data'],
    data() {
        return {
        }
    }
}
</script>


<style scoped>


</style>