<template>
<v-container>
    <v-card class="component1">
        <v-card-title>
            My Lending
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            >
            <template v-slot:item.change="{item}">
                <span v-if="item.change > 0" class="primary--text">{{item.change}}%</span>
                <span v-if="item.change < 0" class="error--text">{{item.change}}%</span>
            </template>
        </v-data-table>
    </v-card>
</v-container>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    'project': 'MakerDAO',
                    'type': 'Collateralized Lending',
                    'invested': '$21,600',
                    'earned': '$235',
                    'change': '2.4'
                }
            ],
            headers: [
                {'text': 'Project',
                'value': 'project'
                },
                {'text':'Type',
                'value': 'type'},
                {'text':'Total Invested',
                'value': 'invested'},
                {'text':'Earned',
                'value': 'earned'},
                {'text':'Current APR',
                'value': 'change'}
            ]
        }
    }
}
</script>