<template>

    <v-card dark class="component1">
                                
        <v-card-title>
            <v-row>
                <v-col align="center" lg=12>
                    <v-sparkline :gradient="gradient" :value="categoryData.value">
                    </v-sparkline>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row dense>
                <v-col align="center" justify="center" lg=12>
                    <span class="headline white--text"> {{categoryData.title}} </span>
                </v-col>
                <v-col align="center" lg=12>
                    <span class="subtitle-1">$2,100 Invested</span><span class="profit--text"> <br>+2.3%</span>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col align="center">
            <v-btn link to="/projects" class="btn background white--text">View</v-btn>
                </v-col>    
            </v-row>
        </v-card-actions>

               <v-overlay
            :value="categoryData.overlay"
            absolute
            color="black"
            opacity="0.8"
          >
            Coming Soon
          </v-overlay>
    </v-card>
    
</template>

<script>


    const gradients = [
        ['#20CCFF', '#FAF595', '#30FFF3'],
        ['#20CCFF', '#FAF595', '#E797FD'],
        ["#E797FD", "#FAF595"]
    ]

export default {

    data() {
        return {
            gradient: gradients[1],
            gradients,
        }
    },
    props:  ['categoryData']
    
}
</script>

<style scoped>


</style>